import prebid from 'prebid.js';
import 'prebid.js/modules/consentManagementGpp';
import 'prebid.js/modules/gppControl_usnat';
import 'prebid.js/modules/gppControl_usstates';
import 'prebid.js/modules/consentManagementTcf';
import 'prebid.js/modules/tcfControl';
import 'prebid.js/modules/consentManagementUsp';
import 'prebid.js/modules/rubiconBidAdapter';
import 'prebid.js/modules/tripleliftBidAdapter';
import 'prebid.js/modules/ixBidAdapter';
import 'prebid.js/modules/appnexusBidAdapter';
import 'prebid.js/modules/openxBidAdapter';
import 'prebid.js/modules/pubmaticBidAdapter';
import 'prebid.js/modules/ttdBidAdapter';
import 'prebid.js/modules/gridBidAdapter';
import 'prebid.js/modules/yahooAdsBidAdapter';
import 'prebid.js/modules/rtdModule/index.js';
import 'prebid.js/modules/gameraRtdProvider';
import 'prebid.js/modules/userId/index.js';
import 'prebid.js/modules/topicsFpdModule';
import 'prebid.js/modules/criteoIdSystem';
import 'prebid.js/modules/pubProvidedIdSystem';
import 'prebid.js/modules/sharedIdSystem';
import 'prebid.js/modules/33acrossIdSystem';
import 'prebid.js/modules/unifiedIdSystem';
import 'prebid.js/modules/connectIdSystem';
import 'prebid.js/modules/liveIntentIdSystem';
import 'prebid.js/modules/paapiForGpt.js';

prebid.processQueue();
